<!--
 * @Descripttion: 主要頁面
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-01 22:47:19
 * @LastEditTime: 2021-11-19 02:20:00
-->
<template>
  <div>

    <el-container>
      <el-header>
    
          <Header />
   
      </el-header>
      <el-main>
        <router-view :bannerWidth="bannerWidth"></router-view>
      </el-main>
      <hr style="width:95%" />
      <el-footer style="height:auto;;text-align: center;">
        <div>
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
             style="text-decoration: none; color: white;">备案号：闽ICP备2020019887号-2</a><b 
               style="color: white; margin: 0px 5px;">|</b><b 
               id="sheji"
               style="color: white;">群主QQ:609776938</b>
        </div>
        <div>
          <img style="height:80px"
               src="~@/assets/footer.gif" />
        </div>

      </el-footer>
    </el-container>

  </div>

</template>

<script>
import Header from "../components/Header.vue";

export default {
  name: "DbgamewebMain",
  props:['bannerWidth'],
  components: { Header },

  directives: {},

  data() {
    return {
 
    };
  },

  mounted() {

    //console.log(    this.bannerWidth)
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
> .el-scrollbar__bar {
opacity: 1 !important;
}
}
</style>